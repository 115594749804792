/* src/pages/GamesDisplayScreen.css */

/* Base styles */
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

.games-display-screen {
  min-height: 100vh;
  padding: 16px;
  background-color: #f9f9f9;
}

.games-display-screen h1 {
  color: #a847e4;
  font-size: 4rem;
  text-align: center;
  margin-bottom: 40px;
  font-family: 'Varela Round', sans-serif;
}

/* Grid layout for games */
.games-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  max-width: 1200px;
  margin: 0 auto;
}

@media (min-width: 640px) {
  .games-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .games-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Individual game card */
.game-card {
  background-color: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.game-card:hover {
  transform: scale(1.05);
}

.game-link {
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.game-image-container {
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #edf2f7;
}

.game-image {
  width: 100%;
  height: auto;
  display: block;
}

.game-details {
  padding: 16px;
  flex-grow: 1;
}

.game-details h2 {
  color: #a847e4;
  font-size: 1.5rem;
  margin-bottom: 8px;
  font-family: 'Varela Round', sans-serif;
}

.game-details p {
  color: #555;
  font-size: 1rem;
}
