@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

* {
  font-family: 'Varela Round', sans-serif;
}

/* Base container for the lessons screen */
.lessons-screen {
  min-height: 100vh;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Main card styling */
.lessons-card {
  background-color: white;
  border-radius: 24px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  padding: 24px;
  width: 100%;
  max-width: 1200px;
}

/* Header styling */
.lessons-header {
  text-align: center;
  margin-bottom: 24px;
}

.lessons-title {
  font-size: 2.5rem;
  color: #a847e4;
  font-family: 'Varela Round', sans-serif;
}

.lessons-subtitle {
  font-size: 1.25rem;
  color: black;
}

/* Tutorial button styling */
.tutorial-container {
  text-align: center;
  margin-bottom: 24px;
}

.tutorial-button {
  background-image: linear-gradient(to right, #ce2828, #e4543d);
  color: white;
  padding: 12px 24px;
  font-size: 1.25rem;
  font-weight: bold;
  border-radius: 9999px;
  cursor: pointer;
  width: 100%; 
  max-width: 400px;
  border: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
  text-decoration: none;
}

.tutorial-button:hover {
  transform: scale(1.05);
}

/* Lessons list styling */
.lessons-list {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0 auto;
  margin-bottom: 10px;
}

/* Lesson item styles */
.lesson-item {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  transition: transform 0.3s ease;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.lesson-item:hover {
  transform: scale(1.05);
}

/* Lesson info styles */
.lesson-info {
  display: flex;
  align-items: center;
  flex-grow: 1; 
}

.lesson-icon {
  background-color: #edf2f7;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 16px;
}

.lesson-title {
  font-size: 1.25rem;
  color: #a847e4;
  flex-grow: 1; 
}

/* Lesson button styling */
.lesson-button {
  background-image: linear-gradient(to right, #a847e4, #cd84f2);
  color: white;
  padding: 12px 24px;
  border-radius: 9999px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-size: 1.25rem;
  outline: none;
  border: none;
}

.lesson-button:hover {
  transform: scale(1.05);
}
