/* Base styles for the home screen */
.home-container {
  text-align: left;
  background: linear-gradient(135deg, #6e44ff, #b642f5, #ff8ae2);
  min-height: 100vh;
  padding: 16px;
  color: white;
  display: flex;
  justify-content: center; /* Horizontally center content */
  align-items: center; /* Vertically center content */
}

/* Welcome title styles */
.welcome-title {
  font-size: 3rem;
  margin-bottom: 40px;
  text-align: center;
  font-family: 'Varela Round', sans-serif;
}

/* Main card styling similar to lesson card */
.main-content {
  background-color: white;
  color: #b642f5;
  border-radius: 24px;
  padding: 24px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 1200px;
  display: flex; 
  flex-direction: column;
}

/* Flex container for the text section and placeholder */
.text-image-container {
  display: flex;
  justify-content: space-between;
  gap: 0px;
}

/* Left section for text */
.text-section {
  flex: 2;
}

/* Placeholder rectangle for image */
.placeholder-rectangle {
  background-color: #b642f5;
  width: 300px;
  height: 325px;
  border-radius: 24px;
  flex: 1;
}

/* Section title styles */
.section-title {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #b642f5;
  font-family: 'Varela Round', sans-serif;
}

/* Indented numbered list styles */
.custom-numbered-list {
  margin-top: 1rem;
  padding-left: 40px; /* Reduce padding to align with the design */
  list-style-type: decimal;
}

.custom-numbered-list li {
  margin-bottom: 0.8rem;
  font-size: 1.2rem;
  color: black;
}

/* Start link styling */
.start-link {
  font-size: 1.5rem;
  color: #b642f5;
  cursor: pointer;
  margin-top: 20px;
  display: inline-block;
  transition: color 0.3s ease;
}

/* Flex container for start-link */
.start-link-container {
  display: flex;
  justify-content: flex-end;
}

.welcome-image {
  width: 100%;
  max-width: 400px; /* Adjust the size to fit your design */
  height: auto;
  border-radius: 10px;
}