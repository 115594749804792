/* General styles */
.game-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #1a1a1a; /* Dark background for contrast */
  padding: 20px;
}

.game-title {
  color: white;
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
}

/* Default Game Container (16:9 aspect ratio) */
.game-container {
  width: 100%;
  max-width: 960px;
  aspect-ratio: 16 / 9; /* Default to 16:9 */
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  background-color: #000; /* Background while game loads */
}

/* Special case for BeatDecoders2.0 (4:3 aspect ratio) */
.game-container.beatdecoders {
  aspect-ratio: 4 / 3; /* Ensures 960x720 */
}

/* Ensure Unity canvas takes full container size */
.game-container #unity-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.game-container #unity-canvas {
  width: 100% !important;
  height: 100% !important;
  border-radius: 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .game-title {
      font-size: 2rem;
  }
}
