@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .right-chevron {
    clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
  }
  .chevron {
    clip-path: polygon(20% 0, 100% 0, 80% 100%, 0 100%);
  }
  .left-chevron {
    clip-path: polygon(20% 0, 100% 0, 100% 100%, 0 100%);
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
