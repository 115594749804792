@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

/* Base container for the tutorial screen */
.tutorial-screen {
  min-height: 100vh;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Main card styling */
.tutorial-card {
  background-color: white;
  border-radius: 24px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  padding: 24px;
  position: relative;
  width: 100%;
  max-width: 1200px;
}

.tutorial-intro {
  text-align: center;
  color:black;
  margin-bottom: 40px;
}

/* Heading styles */
h2 {
  color: #a847e4;
  font-size: 4rem;
  margin-bottom: 16px;
  font-family: 'Varela Round', sans-serif;
}

.tutorial-h2 {
  color: #a847e4;
  font-size: 2rem;
  margin-bottom: 16px;
  font-family: 'Varela Round', sans-serif;
  
}

p {
  font-size: 1.25rem;
  color: black;
}

/* Section for each part of the curriculum */
.tutorial-parts {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
}

@media (min-width: 640px) {
  .tutorial-parts {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .tutorial-parts {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* Individual part container */
.tutorial-part {
  background-color: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

/* Style for the iframe video element */
.tutorial-video {
  width: 100%;
  height: calc(100vw * 9 / 16); /* Maintain a 16:9 aspect ratio */
  max-height: 300px; /* Set max height to avoid overly tall videos */
  min-height: 10px; /* Set min height for smaller screens */
  border-radius: 15px;
  border: none;
}

.tutorial-video-container {
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  background-color: #edf2f7;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.icon-container {
  background-color: #edf2f7;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  color: #a847e4;
}

/* Details about the part */
.part-details {
  padding: 16px;
}

.part-details h3 {
  color: #a847e4;
}

.part-details p {
  color: black;
}

.duration {
  color: #739bce;
  font-size: 0.875rem;
}

/* Full-width, centered button at the bottom */
.to-lessons-container {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.to-lessons-button {
  background-image: linear-gradient(to right, #a847e4, #cd84f2);
  color: white;
  padding: 12px 24px;
  font-size: 1.25rem;
  font-weight: bold;
  border-radius: 9999px;
  cursor: pointer;
  width: 100%;
  max-width: 300px;
  border: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.to-lessons-button:hover {
  transform: scale(1.05);
}
